import React, { useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';

interface IVideoPlayerProps {
    autoPlay?: boolean;
}

const WorkerSafetyStream: React.FC<IVideoPlayerProps> = ({ autoPlay = true }) => {
    const playerRef = useRef<HTMLVideoElement | null>(null);

    const [timeDifference, setTimeDifference] = useState<string>('00:00/00:00');

    const videoUrl = 'https://antmedia.workersafety.aiotdemos.com:5443/WorkerSafetyDev/streams/0-edge-device-stream.m3u8?start=1733191500&end=1733191507';

    // Extract start and end parameters and calculate the difference
    const [totalDuration, setTotalDuration] = useState<number>(0); // Total duration in seconds

    useEffect(() => {
        const urlParams = new URLSearchParams(new URL(videoUrl).search);
        const start = parseInt(urlParams.get('start') || '0', 10);
        const end = parseInt(urlParams.get('end') || '0', 10);

        // Calculate total time difference in seconds
        const differenceInSeconds = end - start;
        setTotalDuration(differenceInSeconds);

    }, [videoUrl]);

    // Format seconds into HH:MM:SS
    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const [currentTime, setCurrentTime] = useState<number>(0);

    // Event handler for time update in the video player
    const handleTimeUpdate = () => {
        if (playerRef.current) {
            setCurrentTime(playerRef.current.currentTime);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (playerRef.current && totalDuration > 0) {
                handleTimeUpdate();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [totalDuration]);

    useEffect(() => {
        const fireOnVideoStart = () => {
            // Do some stuff when the video starts/resumes playing
            console.log("Video started or resumed.");
        };

        if (playerRef.current) {
            playerRef.current.addEventListener('play', fireOnVideoStart);
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.removeEventListener('play', fireOnVideoStart);
            }
        };
    }, []);

    useEffect(() => {
        const fireOnVideoEnd = () => {
            // Do some stuff when the video ends
            console.log("Video ended.");
        };

        if (playerRef.current) {
            playerRef.current.addEventListener('ended', fireOnVideoEnd);
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.removeEventListener('ended', fireOnVideoEnd);
            }
        };
    }, []);

    useEffect(() => {
        const fireOnVideoPause = () => {
            // Do some stuff when the video is paused or manually stopped
            console.log("Video paused or stopped.");
        };

        if (playerRef.current) {
            playerRef.current.addEventListener('pause', fireOnVideoPause);
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.removeEventListener('pause', fireOnVideoPause);
            }
        };
    }, []);

    useEffect(() => {
        const fireOnVideoWaiting = () => {
            // Do some stuff when the video is buffering/waiting for more data
            console.log("Video loading stopped, buffering or waiting for more data.");
        };

        const fireOnVideoError = () => {
            // Do some stuff when an error occurs while loading the video
            console.log("Error occurred while loading the video.");
        };

        if (playerRef.current) {
            playerRef.current.addEventListener('waiting', fireOnVideoWaiting);
            playerRef.current.addEventListener('error', fireOnVideoError);
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.removeEventListener('waiting', fireOnVideoWaiting);
                playerRef.current.removeEventListener('error', fireOnVideoError);
            }
        };
    }, []);

    return (
        <div>
            <h3>Timer: {formatTime(currentTime)}/{formatTime(totalDuration)}</h3>

            <ReactHlsPlayer
                playerRef={playerRef}
                src={videoUrl}
                autoPlay={autoPlay}
                // controls={fa/    lse}
                width="100%"
                height="auto"
                onTimeUpdate={handleTimeUpdate}
            />
        </div>
    );
};

export default WorkerSafetyStream;
