import { useEffect, useMemo, useRef, useState } from 'react';
import { AlertDetailsType } from '../../sdk/types/alert.type';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import { scaleToPercentage } from '../../utils/common.util';
import CustomButton from '../CustomButton/CustomButtonComponent';
import SurveillanceCamera from '../SurveillanceCamera/SurveillanceCameraComponent';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import ZoomablePage from '../zoomable/ZoomablePage';
import './IncedentFeedAndLocationComponent.scss';
import ThreeLineLoader from '../ThreeLineLoader/ThreeLineLoader';
import QuadrilateralOverlay from '../QuadrilateralOverlay/QuadrilateralOverlay';
import HLSVideoPlayer from '../HLSVideoPlayer/HLSVideoPlayer';

interface IIncedentFeedAndLocation {
    alertDetails: AlertDetailsType;
}

const IncedentFeedAndLocation: React.FC<IIncedentFeedAndLocation> = ({ alertDetails }) => {
    const [maxContainerId, setMaxContainerId] = useState<string | null>(null);
    const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);
    const figureRef = useRef<HTMLDivElement | null>(null);
    const [snapshotList, setSnapshotList] = useState<HTMLCanvasElement[]>([]);
    const videoCaptureRef = useRef<{ captureSnapshot: () => void } | null>(null);
    const [videoStartedPlaying, setVideoStartedPlaying] = useState<boolean>(false);


    useEffect(() => {
        const updateDimensions = () => {
            if (figureRef?.current) {
                const { clientWidth, clientHeight } = figureRef.current;
                setDimensions({ width: clientWidth, height: clientHeight });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const dynamicComponents = () => {
        const { coordinateX, coordinateY } = alertDetails?.camera || {} as CameraConfigType;
        const x = scaleToPercentage(coordinateX);
        const y = scaleToPercentage(coordinateY);
        return [{ component: (<SurveillanceCamera />), position: { x, y } }];
    }

    const cameraZoneRenderer = (
        <ZoomablePage
            components={dynamicComponents()}
            showZoomControls={false}
            isImageDraggable={false}
            isCamCreationAllowed={false} />
    );

    const toggleMaximize = (containerName: string) => {
        setMaxContainerId(prev => prev === containerName ? null : containerName);
    }

    const onGettingSnapshot = (canvas: HTMLCanvasElement) => {
        if (snapshotList?.length === 4) {
            return;
        }
        setSnapshotList((prevList) => [...prevList, canvas]);
        updateSnapshotList(snapshotList);
    }

    const updateSnapshotList = (newList: any[]) => {
        if (snapshotList?.length > 4) {
            // Always ensure the snapshot list has a maximum of 4 items
            setSnapshotList(newList.slice(0, 4));
        }
    };

    const handleVideoPlay = () => {
        if (videoCaptureRef.current) {
            if (!videoStartedPlaying) {
                setVideoStartedPlaying(true);
            }
            // videoCaptureRef.current.shoot();
            // for (let i = 0; i < 4; i++) {
            //     videoCaptureRef.current.shoot(); // Call the shoot function from VideoCapture
            // }

            let count = 0;
            const intervalId = setInterval(() => {
                if (videoCaptureRef.current && count < 4) {
                    videoCaptureRef.current.captureSnapshot(); // Call the shoot method
                    count++;
                } else {
                    clearInterval(intervalId); // Stop after capturing 4 snapshots
                }
            }, 1000);
        }
    };

    const recordedVideoRenderer = useMemo(() => {
        return (
            <li className='item recording'>
                <section className="content">
                    {
                        alertDetails?.videos?.length ? (
                            <figure>
                                <QuadrilateralOverlay
                                    mediaUrl={alertDetails?.videos[0] || ''}
                                    isIframe={false}
                                    isVideo={true}
                                    canDrag={false}
                                    showLines={!!alertDetails?.regionCoordinates?.length}
                                    showPoints={false}
                                    initialPoints={alertDetails?.regionCoordinates}
                                    customComponent={
                                        <div style={{ height: '100%' }}>
                                            <HLSVideoPlayer
                                                ref={videoCaptureRef}
                                                videoUrl={alertDetails?.videos[0]}
                                                onCaptureSnapshot={onGettingSnapshot}
                                                onVideoPlay={handleVideoPlay}
                                            />
                                        </div>
                                    }
                                />
                            </figure>
                        ) : (
                            <div className='loader'>
                                <ThreeLineLoader />
                            </div>
                        )
                    }
                </section>
            </li>
        );
    }, [alertDetails?.videos?.length]);

    const incidentImageListRenderer = useMemo(() => {
        // if (snapshotList.length > 4) {
        //     return null; // Do not call or render anything if snapshotList has more than 4 items
        // }
        const combinedImages: (string | HTMLCanvasElement)[] = [...snapshotList];
        updateSnapshotList(snapshotList);

        if (!combinedImages?.length) {
            return (
                <div className='loader'>
                    <ThreeLineLoader />
                </div>
            )
        }

        return combinedImages?.length > 0 && (
            [combinedImages.slice(0, 2), combinedImages.slice(2, 4)].map((srcList: (string | HTMLCanvasElement)[], iIndex: number) => (
                <ol className="container inner-container" key={iIndex}>
                    {srcList.map((dataSrc: string | HTMLCanvasElement, jIndex: number) => (
                        <li key={jIndex} className={`item incident-image${iIndex}${jIndex} ${maxContainerId == `incident-image${iIndex}${jIndex}` ? 'max' : ''}`}>
                            <section className="content">
                                <figure>
                                    {typeof dataSrc === 'string' ? (
                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                        <img
                                            src={dataSrc}
                                            alt={`Incident image ${iIndex}${jIndex}`}
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Ensure img takes full container
                                            onError={(e) => {
                                                e.currentTarget.style.display = 'none';
                                                const errorMessage = e.currentTarget.nextElementSibling;
                                                const toggleMaxActionBtn = e.currentTarget?.parentElement?.nextElementSibling;
                                                if (errorMessage) {
                                                    (errorMessage as HTMLElement).style.display = 'flex';
                                                    (toggleMaxActionBtn as HTMLElement).style.display = 'none';
                                                }
                                            }}
                                        />
                                    ) : (
                                        <canvas
                                            style={{ width: '100%', height: '100%' }} // Ensure canvas takes full container
                                            ref={(canvasRef) => {
                                                if (canvasRef) {
                                                    // Set canvas size based on its container size
                                                    canvasRef.width = canvasRef.clientWidth;
                                                    canvasRef.height = canvasRef.clientHeight;

                                                    const ctx = canvasRef.getContext('2d');
                                                    if (ctx) {
                                                        ctx.clearRect(0, 0, canvasRef.width, canvasRef.height); // Clear the canvas
                                                        ctx.drawImage(dataSrc, 0, 0, canvasRef.width, canvasRef.height); // Stretch image to fit canvas
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                    <span className="error-message">Image failed to load</span>
                                </figure>
                                <footer className="actions">
                                    <CustomButton
                                        icon={<SvgIcon name={`${maxContainerId == `incident-image${iIndex}${jIndex}` ? 'minimize' : 'maximize'}`} height={16} />}
                                        onClick={() => toggleMaximize(`incident-image${iIndex}${jIndex}`)}
                                    />
                                </footer>
                            </section>
                        </li>
                    ))}
                </ol>
            ))
        );
    }, [snapshotList, maxContainerId, toggleMaximize]);

    return (
        <section className="incident-feed-and-location">
            <ul className='container'>
                <li className={`item live-feed ${maxContainerId === 'live-feed' ? 'max' : ''}`}>
                    <section className="content">
                        <figure style={{ width: `${dimensions?.width}px`, height: `${dimensions?.height}` }}>

                            <QuadrilateralOverlay
                                mediaUrl={alertDetails?.camera?.iframeUrl || ''}
                                isIframe={true}
                                isVideo={false}
                                canDrag={false}
                                showLines={!!alertDetails?.regionCoordinates?.length}
                                showPoints={false}
                                initialPoints={alertDetails?.regionCoordinates}
                            />
                        </figure>
                    </section>
                </li>
                {recordedVideoRenderer}
            </ul>

            <ul className='container'>
                <li className='item nested'>
                    <section className="content">
                        {incidentImageListRenderer}
                    </section>
                </li>
                <li className={`item cam-zone ${maxContainerId == 'cam-zone' ? 'max' : ''}`}>
                    <section className="content">
                        <div className="zone-container">
                            {cameraZoneRenderer}
                        </div>
                        <footer className="actions">
                            <CustomButton
                                icon={<SvgIcon name={`${maxContainerId == 'cam-zone' ? 'minimize' : 'maximize'}`} height={16} />}
                                onClick={() => toggleMaximize('cam-zone')} />
                        </footer>
                    </section>

                </li>
            </ul>

        </section>
    )
}

export default IncedentFeedAndLocation;