import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AlertBox from '../../components/AlertBox/AlertBoxComponent';
import AlertDetailsPopup from '../../components/AlertDetailsPopup/AlertDetailsPopupComponent';
import CameraView from '../../components/CameraView/CameraViewComponent';
import CustomDialog from '../../components/CustomDialog/CustomDialogComponent';
import useCustomDialogHandler from '../../components/CustomDialog/useCustomDialogHandler';
import DeleteAlertDialog from '../../components/DeleteAlertDialog/DeleteAlertDialog';
import EventCountBar from '../../components/EventCountBar/EventCountBarComponent';
import ExpandableAlertList from '../../components/ExpandableAlertList/ExpandableAlertList';
import SvgIcon from '../../components/SvgIcons/SvgIconComponent';
import ToastService from '../../components/Toast/ToastService';
import ZoomablePage, { ZoomablePageMethods } from '../../components/zoomable/ZoomablePage';
import ZoomControls from '../../components/ZoomControls/ZoomControlsComponent';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { deleteAlert, selectAllAlerts, selectResolvedAlerts, selectUnresolvedAlerts, setAlertList, updateAlert } from '../../sdk/redux/slices/alertSlice';
import { selectProcessedBannerCountList, setBannerCountList } from '../../sdk/redux/slices/bannerSlice';
import { RootState } from '../../sdk/redux/store/store';
import alertService from '../../sdk/services/alertService';
import { AlertDetailsType } from '../../sdk/types/alert.type';
import { CameraDetailsInFloorMapType } from '../../sdk/types/cameraConfig.type';
import { formatTimestamp, getLatestAlertByCameraId, scaleToPercentage } from '../../utils/common.util';
import './AlertsPage.scss';
import { useAuth } from '../../context/AuthContext';

const AlertsPage: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { user, logout } = useAuth();

    const [showType, setShowType] = useState<'all' | 'resolved' | 'unresolved'>('all');
    const [cameraListDetails, setCameraListDetails] = useState<CameraDetailsInFloorMapType[]>([]);
    const [selectedCamView, setSelectedCamView] = useState<string>('');
    const [selectedAlert, setSelectedAlert] = useState<AlertDetailsType | null>(null);
    const [incidentToDelete, setIncidentToDelete] = useState<AlertDetailsType | null>(null);
    const [hideSideLayoutDrawer, setHideSideLayoutDrawer] = useState<boolean>(false);

    const [showAlertOnCamView, setShowAlertOnCamView] = useState<boolean>(true);
    const [selectedAlertToShowOnCamView, setSelectedAlertToShowOnCamView] = useState<AlertDetailsType | null>(null);

    const bannerCountList = useSelector(selectProcessedBannerCountList);

    const alertList = useSelector((state: RootState) => state.alert.alertList);
    const resolvedAlerts = useSelector(selectResolvedAlerts);
    const unresolvedAlerts = useSelector(selectUnresolvedAlerts);
    const allAlerts = useSelector(selectAllAlerts);

    const { openDialog, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog } = useCustomDialogHandler();
    const pageRef = useRef<HTMLDivElement>(null);
    const zoomablePageRef = useRef<ZoomablePageMethods>(null);

    // Flags to track if each API is in progress
    const [fetchAlertsInProgress, setFetchAlertsInProgress] = useState<boolean>(false);
    const [fetchCameraDetailsInProgress, setFetchCameraDetailsInProgress] = useState<boolean>(false);
    const [fetchBannerCountInProgress, setFetchBannerCountInProgress] = useState<boolean>(false);

    const [isPageVisible, setIsPageVisible] = useState(true);

    const [isLogoutTriggered, setIsLogoutTriggered] = useState(false); // To ensure logout only happens once

    // Store the timeout IDs for each API request
    const timeoutIds = useRef<{ [key: string]: NodeJS.Timeout | null }>({
        fetchAlerts: null,
        fetchCameraDetails: null,
        fetchBannerCount: null,
    });

    const logoutTimeoutId = useRef<NodeJS.Timeout | null>(null); // Timeout for logout

    // Flag to track if the component is mounted
    const isMounted = useRef(true);

    // Function to handle visibility changes
    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            setIsPageVisible(true); // Page is active
        } else {
            setIsPageVisible(false); // Page is inactive
             // Start the logout timeout when the page goes into the background
             logoutTimeoutId.current = setTimeout(() => {
                // triggerLogout();
            }, 5 * 60 * 1000); // 5 minutes in milliseconds
        }
    };

    // Function to trigger logout
    const triggerLogout = () => {
        if (!isLogoutTriggered) {
            setIsLogoutTriggered(true);
            console.log('Logging out due to inactivity');
            logout();
        }
    };

    // Set up the visibility change event listener on mount
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        // If the page is visible, start fetching data
        if (isPageVisible) {
            fetchAlerts();
            fetchCameraDetails();
            fetchBannerCount();
        } else {
            // If the page is not visible, cancel the scheduled fetches
            Object.values(timeoutIds.current).forEach(timeoutId => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            });
        }
    }, [isPageVisible]); // This will re-run whenever visibility changes

    // Function to fetch alerts
    const fetchAlerts = async () => {
        if (fetchAlertsInProgress || !isPageVisible) return; // Only one call for the same API
        setFetchAlertsInProgress(true);
        try {
            const data = await alertService.getAllAlerts();
            dispatch(setAlertList(data));
        } catch (error) {
        } finally {
            if (isMounted.current && isPageVisible) {
                setFetchAlertsInProgress(false);
                scheduleNextFetch('fetchAlerts'); // Schedule the next fetch for alerts after 5 seconds
            }
        }
    };

    // Function to fetch camera details
    const fetchCameraDetails = async () => {
        if (fetchCameraDetailsInProgress || !isPageVisible) return; // Only one call for the same API
        setFetchCameraDetailsInProgress(true);
        try {
            const data = await alertService.getAllFloorMapCameraDetails();
            setCameraListDetails(data); // Set the response data to state
        } catch (error) {
        } finally {
            if (isMounted.current && isPageVisible) {
                setFetchCameraDetailsInProgress(false);
                scheduleNextFetch('fetchCameraDetails'); // Schedule the next fetch for camera details after 5 seconds
            }
        }
    };

    // Function to fetch banner count
    const fetchBannerCount = async () => {
        if (fetchBannerCountInProgress || !isPageVisible) return; // Only one call for the same API
        setFetchBannerCountInProgress(true);

        try {
            const data = await alertService.getAlertBannerCountList();
            dispatch(setBannerCountList(data));
        } catch (error) {
        } finally {
            if (isMounted.current && isPageVisible) {
                setFetchBannerCountInProgress(false);
                scheduleNextFetch('fetchBannerCount'); // Schedule the next fetch for banner count after 5 seconds
            }
        }
    };

    const scheduleNextFetch = (apiType: string) => {
        if (timeoutIds.current[apiType]) {
            clearTimeout(timeoutIds.current[apiType]!);
        }
        timeoutIds.current[apiType] = setTimeout(() => {
            switch (apiType) {
                case 'fetchAlerts': fetchAlerts(); // Call fetchAlerts after 5 seconds
                    break;
                case 'fetchCameraDetails': fetchCameraDetails(); // Call fetchCameraDetails after 5 seconds
                    break;
                case 'fetchBannerCount': fetchBannerCount(); // Call fetchBannerCount after 5 seconds
                    break;
                default:
                    break;
            }
        }, 5000); // 5 seconds delay before the next API call
    };

    useEffect(() => {
        isMounted.current = true;
        fetchAlerts();
        fetchCameraDetails();
        fetchBannerCount();

        return () => {
            isMounted.current = false; // Mark as unmounted
            Object.values(timeoutIds.current).forEach(timeoutId => {
                if (timeoutId) {
                    clearTimeout(timeoutId); // Clear each timeout
                }
            });
            if (logoutTimeoutId.current) {
                clearTimeout(logoutTimeoutId.current); // Cancel the logout timeout
            }
        };
    }, []); // Empty dependency array ensures this effect runs only once when the component mounts

    useEffect(() => {
        Object.values(timeoutIds.current).forEach(timeoutId => {
            if (timeoutId) {
                clearTimeout(timeoutId); // Clear each timeout
            }
        });
    }, [location]);

    const fetchData = async () => {
        const akerts = await alertService.getAllAlerts();
        dispatch(setAlertList(akerts));

        const cameraDetails = await alertService.getAllFloorMapCameraDetails();
        setCameraListDetails(cameraDetails); // Set the response data to state

        const bannerCountList = await alertService.getAlertBannerCountList();
        setBannerCountList(bannerCountList);
    }

    const handleAlertViewDetailsClick = useCallback((alert: AlertDetailsType) => {
        setSelectedAlert(alert);
        handleOpenDialog();
    }, [handleOpenDialog]);

    const handleAlertToExpandInListView = (alert: AlertDetailsType) => {
        setSelectedCamView(prev => (alert?.camera?.cameraId || ''));
        setSelectedAlertToShowOnCamView(alert);
        setShowAlertOnCamView(true);
    }

    const handleCameraViewPinClick = (cam: CameraDetailsInFloorMapType) => {
        setShowAlertOnCamView(true);
        setSelectedCamView(prev => (cam?.cameraId || ''));
        if (cam?.cameraId) {
            const latestAlert = getLatestAlertByCameraId(cam?.cameraId, allAlerts);
            setSelectedAlertToShowOnCamView(latestAlert);
        }
    }

    const triggerResolveAlert = async (alert: AlertDetailsType) => {
        try {
            const updatedResult = await alertService.updateAlert(alert.id, true, alert.deleted);
            if (updatedResult) {
                dispatch(updateAlert({ id: alert.id, updatedAlertResult: updatedResult }));
                ToastService.showToast('Resolve alert', `Alert resolved successfully`, 'success', 3000);
            }
        } catch (error: any) {
            const { errorCode, errorInfo } = error?.response?.data;
            let errorMessage = errorInfo || 'Something went wrong';
            ToastService.showToast('Resolve alert', errorMessage, 'error');
        }
    }

    const showDeleteAlertPopup = (alert: AlertDetailsType) => {
        setIncidentToDelete(alert);
    }

    const triggerDeleteAlert = async () => {
        if (incidentToDelete) {
            try {
                await alertService.updateAlert(incidentToDelete?.id, incidentToDelete?.resolved, true);
                handleCloseDialog();
                if (selectedAlertToShowOnCamView?.id == incidentToDelete?.id) {
                    setSelectedAlertToShowOnCamView(null);
                    setSelectedCamView('');
                }
                dispatch(deleteAlert(incidentToDelete.id));
                ToastService.showToast('Delete alert', `Alert deleted successfully`, 'success', 3000);
                setIncidentToDelete(null);
                fetchData();
            } catch (error: any) {
                const { errorCode, errorInfo } = error?.response?.data;
                let errorMessage = errorInfo || 'Something went wrong';
                ToastService.showToast('Delete alert', errorMessage, 'error');
            }
        }
    }

    const alertsToDisplay = useMemo(() => {
        switch (showType) {
            case 'resolved':
                return resolvedAlerts;
            case 'unresolved':
                return unresolvedAlerts;
            default:
                return allAlerts;
        }
    }, [showType, resolvedAlerts, unresolvedAlerts, allAlerts]);

    const handleUpdatedHideDrawerChanges = (flag: boolean) => {
        console.log(flag);
        setHideSideLayoutDrawer(flag);
    }

    const dialogContent = () => {
        return (
            <AlertDetailsPopup
                selectedAlert={selectedAlert}
                allAlerts={allAlerts}
                triggerResolveAlert={triggerResolveAlert}
                showDeleteAlertPopup={showDeleteAlertPopup}
            />
        );
    };

    const handleAlertBoxCloseBtnClick = () => {
        setShowAlertOnCamView(false);
        setSelectedAlertToShowOnCamView(null); // this will close the expanded alert on ExpandableAlertList section
    }

    const alertBoxRenderer = useMemo(() => {
        const alertToShow = allAlerts.find((alrt: AlertDetailsType) => alrt.id == selectedAlertToShowOnCamView?.id);
        if (!alertToShow) {
            return;
        }
        return (
            <>
                <AlertBox
                    headerLabel={alertToShow?.alertName} //"Unattended"
                    subHeaderLabel={alertToShow?.camera?.location}
                    subHeaderIcon={<SvgIcon name='location' />}
                    footerLabel={alertToShow?.camera?.cameraId}
                    timestamp={formatTimestamp(alertToShow?.alertTimestamp || 0)}
                    showStatus={true}
                    showActionSection={true}
                    showViewDetailsBtn={true}
                    isAlertResolved={alertToShow?.resolved}
                    showCloseBtn={true}
                    thumbnails={{
                        image: alertToShow?.images?.length ? alertToShow?.images[0] : '',
                        video: alertToShow?.videos[0] || '',
                    }}
                    onAlertViewDetailsClick={() => handleAlertViewDetailsClick(alertToShow)}
                    onResolveBtnClick={() => triggerResolveAlert(alertToShow)}
                    onDeleteBtnClick={() => showDeleteAlertPopup(alertToShow)}
                    onCloseBtnClick={() => handleAlertBoxCloseBtnClick()}
                    onImageThumbnailClick={() => handleAlertViewDetailsClick(alertToShow)}
                    onVideoThumbnailClick={() => handleAlertViewDetailsClick(alertToShow)}
                />
            </>
        );
    }, [selectedCamView, alertsToDisplay, selectedAlertToShowOnCamView, allAlerts]);

    const toggleResolved = () => {
        setShowType(prev => prev == 'all' ? 'resolved' : (prev == 'resolved' ? 'unresolved' : 'all'));
    }

    const dynamicComponentList = useMemo(() => {
        return cameraListDetails?.map((cam: CameraDetailsInFloorMapType, index: number) => {
            const totalAlerts = cam?.totalAlerts || 0;
            let alertCount = (totalAlerts > 999 ? `${totalAlerts.toString().charAt(0)}K+` : totalAlerts);
            return {
                component: (
                    <>
                        <section className="alert-box-and-cam-view">
                            <section className='alert-box-container' onClick={(e) => e.stopPropagation()}>
                                {(selectedAlertToShowOnCamView?.camera?.cameraId == cam?.cameraId) && showAlertOnCamView && alertBoxRenderer}
                            </section>
                            <div onClick={(e) => setSelectedCamView('')}>
                                <CameraView
                                    cameraAngle={cam?.cameraAngle}
                                    fieldOfView={cam?.fieldOfView}
                                    showPin={true}
                                    pinTxt={`${alertCount}`}
                                    onPinClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                        event.stopPropagation();
                                        if (totalAlerts == 0) return;
                                        handleCameraViewPinClick(cam);
                                    }}
                                />
                            </div>
                        </section>
                    </>
                ),  // assuming cameraId is unique
                position: { x: scaleToPercentage(cam.coordinateX), y: scaleToPercentage(cam.coordinateY) },
                increaseZIndex: selectedAlertToShowOnCamView?.camera?.cameraId == cam?.cameraId || false,
                id: cam.cameraId,
            }
        })
    }, [cameraListDetails, selectedCamView, alertBoxRenderer, allAlerts, showAlertOnCamView]);

    const mainContent = (
        <div ref={pageRef} className='alert-main-content'>
            <br />
            <EventCountBar countList={bannerCountList} />

            <div onClick={(e) => e.stopPropagation()}>
                <CustomDialog
                    headerLabel={`Cam ID - ${selectedAlert?.camera?.cameraId} - ${selectedAlert?.alertName || ''}`}
                    open={openDialog}
                    content={dialogContent()}
                    dialogMaxWidth='lg'
                    showBottomActionBlock={false}
                    onClose={handleCloseDialog}
                    onCancel={handleCancelDialog}
                    onSave={handleSaveDialog}
                />
            </div>

            <br />

            <section className="floormap-block">
                <figure className="floormap-container">
                    <ZoomablePage
                        ref={zoomablePageRef}
                        components={dynamicComponentList}
                        showZoomControls={false}
                        isImageDraggable={true}
                        isCamCreationAllowed={false}
                    />
                </figure>
                <ZoomControls
                    handleZoomIn={() => zoomablePageRef.current?.handleZoomIn()}
                    handleZoomOut={() => zoomablePageRef.current?.handleZoomOut()}
                    handleReset={() => zoomablePageRef.current?.handleReset()}
                    handleFullscreen={() => { zoomablePageRef.current?.handleReset(); setHideSideLayoutDrawer(true); }}
                />
            </section>
        </div>
    );

    return (
        <>
            <section className='alerts-page' onClick={(e) => setSelectedCamView('')}>
                <MainLayout
                    listPosition="right"
                    showSlideSideContainerBtn={true}
                    listContent={
                        <ExpandableAlertList
                            setFilterBtnClassName={showType}
                            items={alertsToDisplay}
                            showInPopupDesign={false}
                            showStatus={true}
                            alertToShowViewDetails={selectedAlertToShowOnCamView}
                            onSelectingAlertToShowAction={handleAlertToExpandInListView}
                            onAlertViewDetailsClick={handleAlertViewDetailsClick}
                            onResolveBtnClick={triggerResolveAlert}
                            onDeleteBtnClick={showDeleteAlertPopup}
                            onToggleResolveIconClick={toggleResolved}
                            onImageThumbnailClick={handleAlertViewDetailsClick}
                            onVideoThumbnailClick={handleAlertViewDetailsClick}
                        />
                    }
                    mainContent={mainContent}
                    hideDrawer={hideSideLayoutDrawer}
                    updatedHideDrawerChanges={handleUpdatedHideDrawerChanges}
                />
            </section>

            <DeleteAlertDialog
                open={!!incidentToDelete}
                onClose={() => setIncidentToDelete(null)}
                onCancel={() => setIncidentToDelete(null)}
                onSave={triggerDeleteAlert}
                title='Delete Incident'
                saveText='DELETE'
                cancelText='CANCEL'
                content={
                    <section className="delete-cam-dialog-content">
                        <header>Are you sure you want to delete this incident? </header>
                        <p>you will not be able to recover this incident</p>
                    </section>
                }
            />
        </>
    );
};

export default AlertsPage;
