import React from 'react';
import { AlertDetailsType } from '../../sdk/types/alert.type';
import AlertBox from '../AlertBox/AlertBoxComponent';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import { formatTimestamp } from '../../utils/common.util';
import IncedentFeedAndLocation from '../IncedentFeedAndLocation/IncedentFeedAndLocationComponent';

interface AlertDetailsPopupProps {
    selectedAlert: AlertDetailsType | null;
    allAlerts: AlertDetailsType[];
    triggerResolveAlert: (alert: AlertDetailsType) => void;
    showDeleteAlertPopup: (alert: AlertDetailsType) => void;
}

const AlertDetailsPopup: React.FC<AlertDetailsPopupProps> = ({
    selectedAlert,
    allAlerts,
    triggerResolveAlert,
    showDeleteAlertPopup,
}) => {
    if (!selectedAlert) {
        return null;
    }

    const alertToShow = allAlerts.find((alrt) => alrt.id === selectedAlert?.id) || selectedAlert;
    const filteredList = allAlerts.filter(
        (alert) => alert?.camera?.cameraId === selectedAlert?.camera?.cameraId
    );

    return (
        <section className="dialog-content">
            <aside className="side-content">
                <AlertBox
                    headerLabel={alertToShow?.alertName || ''}
                    subHeaderLabel={alertToShow?.camera?.location}
                    subHeaderIcon={<SvgIcon name="location" />}
                    footerLabel={alertToShow?.camera?.cameraId}
                    timestamp={formatTimestamp(alertToShow?.alertTimestamp || 0)}
                    showActionSection={true}
                    isAlertResolved={alertToShow?.resolved}
                    showInPopupDesign={true}
                    showStatus={true}
                    severityLevel="Severity Level 1"
                    showMediaThumbnail={false}
                    onResolveBtnClick={() => triggerResolveAlert(alertToShow)}
                    onDeleteBtnClick={() => showDeleteAlertPopup(alertToShow)}
                />

                <h4>{`Camera #${selectedAlert?.camera.cameraId} Past Alerts`}</h4>
                <section className="past-alert-container">
                    <ul className="past-alerts">
                        {filteredList.map((alert) => (
                            <li key={alert.id} className="alert-info-item">
                                <header>
                                    <h3>{alert.alertName}</h3>
                                    <mark className={alert.resolved ? 'resolved' : 'unresolved'}></mark>
                                </header>
                                <footer>
                                    <figure>
                                        <SvgIcon name="location" />
                                        <figcaption>{alert.camera.location}</figcaption>
                                    </figure>
                                    <time>{formatTimestamp(alert?.alertTimestamp || 0)}</time>
                                </footer>
                            </li>
                        ))}
                    </ul>
                </section>
            </aside>
            <div className="main-content">
                <IncedentFeedAndLocation alertDetails={selectedAlert || {} as AlertDetailsType} />
            </div>
        </section>
    );
};

export default AlertDetailsPopup;
