import { configureStore } from "@reduxjs/toolkit";
import userReducer from '../slices/userSlice';
import productReducer from '../slices/productSlice';
import cameraReducer from '../slices/cameraSlice';
import alertReducer from '../slices/alertSlice';
import bannerReducer from '../slices/bannerSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        product: productReducer,
        camera: cameraReducer,
        alert: alertReducer,
        banner: bannerReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;