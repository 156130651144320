import React from 'react';
import CustomButton from '../CustomButton/CustomButtonComponent';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import './ZoomControlsComponent.scss';

interface IZoomControls {
    handleZoomIn: () => void;
    handleZoomOut: () => void;
    handleReset: () => void;
    handleFullscreen: () => void;
}

const ZoomControls: React.FC<IZoomControls> = ({ handleZoomIn, handleZoomOut, handleReset, handleFullscreen }) => {
    return (
        <footer className="zoom-controls">
            <CustomButton
                icon={<SvgIcon name="zoomIn" width={16} height={16} />}
                variant="contained"
                onClick={handleZoomIn}
            />
            <CustomButton
                icon={<SvgIcon name="zoomOut" width={16} height={16} />}
                variant="contained"
                onClick={handleZoomOut}
            />
            <CustomButton
                icon={<SvgIcon name="fullScreen" width={16} height={16} />}
                variant="contained"
                onClick={handleReset}
            />
            <CustomButton
                icon={<SvgIcon name="maximize" width={16} height={16} />}
                variant="contained"
                onClick={handleFullscreen}
            />
        </footer>
    );
};

export default ZoomControls;
