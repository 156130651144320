import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { IEventCounter } from '../../../components/EventCountBar/EventCountBarComponent';
import { RootState } from '../store/store';
import { BannerDetailsType } from '../../types/alert.type';
import { updateBannerCountList } from '../../../utils/common.util';

interface BannerState {
    bannerCountList: BannerDetailsType[];
    loading: boolean;
}

const initialState: BannerState = {
    bannerCountList: [],
    loading: false,
};

const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        setBannerCountList: (state, action: PayloadAction<BannerDetailsType[]>) => {
            state.bannerCountList = action.payload;
        },
        setBannerLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { setBannerCountList, setBannerLoading } = bannerSlice.actions;

export const selectBannerCountList = (state: RootState) => state.banner.bannerCountList;
export const selectProcessedBannerCountList = (state: RootState) => updateBannerCountList(state.banner.bannerCountList);

export default bannerSlice.reducer;
