import Hls from 'hls.js';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

interface HLSVideoPlayerProps {
    videoUrl: string; // The URL of the video stream (HLS or MP4)
    onCaptureSnapshot?: (snapshot: HTMLCanvasElement) => void;
    onVideoPlay?: () => void;
    autoPlay?: boolean;
}

const HLSVideoPlayer = forwardRef<{ captureSnapshot: () => void }, HLSVideoPlayerProps>(({ videoUrl, onCaptureSnapshot, onVideoPlay, autoPlay = true }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [hlsInstance, setHlsInstance] = useState<Hls | null>(null);
    const [isHLS, setIsHLS] = useState<boolean>(false); // To track if HLS.js is being used
    const scaleFactor = 0.25; // Adjust the scale for the snapshot

    // Function to check if the video format is HLS (m3u8)
    const isHLSFormat = (url: string): boolean => {
        return url.toLowerCase().includes('.m3u8');
    };

    useEffect(() => {
        if (isHLSFormat(videoUrl)) {
            // If it's an HLS stream, use HLS.js to load the stream
            setIsHLS(true);
            if (videoRef.current) {
                const hls = new Hls({
                    startLevel: -1, // Try to start from the best available level
                    fragLoadingTimeOut: 10000, // Set timeout for fragment loading
                    maxBufferLength: 30, // Max buffer length before stopping
                    maxBufferSize: 50 * 1000 * 1000, // Max buffer size in bytes
                    maxMaxBufferLength: 60, // Max total buffer length
                });
                hls.loadSource(videoUrl);
                hls.attachMedia(videoRef.current);

                // Add detailed error handling
                hls.on(Hls.Events.ERROR, (event, data) => {
                    console.error('HLS.js error', data);
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.error('Network error while loading the HLS stream');
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.error('Media error - unable to decode media');
                                break;
                            case Hls.ErrorTypes.OTHER_ERROR:
                                console.error('Other error occurred');
                                break;
                            // case Hls.ErrorTypes.INTERNAL_ERROR:
                            //     console.error('Internal error in HLS.js');
                            //     break;
                        }
                    }
                });

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log('Manifest loaded successfully');
                });

                setHlsInstance(hls);
            }
        } else {
            // If it's a format like MP4, use the native video element
            setIsHLS(false);
            if (videoRef.current) {
                videoRef.current.src = videoUrl; // Directly use the video URL
            }
        }

        // Cleanup HLS.js instance if needed
        return () => {
            if (hlsInstance) {
                hlsInstance.destroy();
            }
        };
    }, [videoUrl]);

    const captureSnapshot = () => {
        if (videoRef.current) {
            const canvas = capture(videoRef.current, scaleFactor);
            if (onCaptureSnapshot) {
                onCaptureSnapshot(canvas); // Pass the captured snapshot to the parent
            }
        }
    };

    const capture = (video: HTMLVideoElement, scaleFactor: number): HTMLCanvasElement => {
        if (!video) return document.createElement('canvas');

        const w = video.videoWidth * scaleFactor;
        const h = video.videoHeight * scaleFactor;
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;

        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.drawImage(video, 0, 0, w, h);
        }
        return canvas;
    };

    const handleCanPlay = () => {
        // The video is ready to start playing
        console.log('Video is ready to play');
    };

    const handlePlay = () => {
        // Capture snapshot when the video starts playing
        // captureSnapshot();
        if (onVideoPlay) {
            onVideoPlay();
        }
    };

    // Expose the captureSnapshot function to the parent component
    useImperativeHandle(ref, () => ({
        captureSnapshot,
    }));

    return (
        <div>
            <video
                ref={videoRef}
                controls
                autoPlay={autoPlay} // Use the `autoPlay` prop to control autoplay
                muted // Ensure video is muted for autoplay to work
                onCanPlay={handleCanPlay} // Video is ready to play
                onPlay={handlePlay} // Capture snapshot when the video starts playing
                style={{ width: '100%', height: 'auto' }}
            >
                {/* For MP4 or other non-HLS formats */}
                {!isHLS && <source src={videoUrl} type="video/mp4" />}
                {/* HLS stream will be handled by HLS.js (no need for <source> here) */}
                Your browser does not support the video tag.
            </video>
        </div>
    );
});

export default HLSVideoPlayer;
